@use "../../styles/mixins" as *;
@include layer(ui, checkbox) {
    .input-checkbox {
        &:focus-visible + .indicator {
            border-color: var(--s-color-field-border-focus);
            border-width: var(--s-border-width-field-focus);
            outline-color: var(--s-color-field-border-focus);
            outline-offset: var(--g-spacing-xxs);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
        }

        &[aria-disabled="true"] + .indicator {
            border-color: var(--s-color-disabled-primary);
        }

        &[aria-invalid="true"]:not([aria-disabled="true"]) + .indicator {
            border-color: var(--s-color-field-border-error);
        }

        &:checked {
            & + .indicator {
                background: var(--s-color-selection-background-selected-bold);
                border-color: var(--s-color-selection-background-selected-bold);

                .icon {
                    color: var(--s-color-selection-foreground-selected-bold);
                    visibility: visible;
                }
            }

            &[aria-disabled="true"] + .indicator {
                background: var(--s-color-disabled-primary);
                border-color: var(--s-color-disabled-primary);
            }
        }
    }

    .indicator-wrapper {
        align-items: center;
        display: inline-flex;
        height: 1lh;
        left: 0;
        position: absolute;
    }

    .indicator {
        align-items: center;
        border-radius: var(--s-border-radius-field-small);
        border-color: var(--s-color-field-border-default);
        border-style: solid;
        border-width: var(--s-border-width-field-default);
        box-sizing: border-box;
        display: inline-grid;
        justify-content: center;
        height: var(--s-size-height-field-small);
        width: var(--s-size-height-field-small);
    }

    .icon {
        font-size: var(--g-size-xxs);
        visibility: hidden;
    }

    .label-wrapper {
        color: var(--s-color-text-primary);
        cursor: pointer;
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        justify-content: space-between;
        line-height: var(--g-line-height-400);
        padding-left: calc(
            var(--s-size-height-field-small) + var(--g-spacing-md)
        );
        position: relative;

        &--disabled {
            &,
            .required-marker {
                color: var(--s-color-disabled-primary);
            }
            cursor: not-allowed;
        }

        &:hover,
        &:active {
            .input-checkbox:not(:checked):not([aria-disabled="true"])
                + .indicator {
                border-color: var(--s-color-field-border-active);
                border-width: var(--s-border-width-field-active);
            }
        }

        &--detail-right {
            gap: var(--g-spacing-md);
        }

        &--detail-below {
            flex-direction: column;
            gap: var(--g-spacing-xxs);
        }
    }

    .label-text--required {
        margin-right: var(--g-spacing-sm);
    }

    .label-detail {
        font-weight: var(--g-font-weight-light);

        &--right {
            text-align: right;
        }
    }

    .required-marker {
        color: var(--s-color-text-quiet);
    }

    .description-wrapper {
        margin-left: calc(
            var(--s-size-height-field-small) + var(--g-spacing-md)
        );
        margin-top: var(--g-spacing-xxs);

        & > * {
            margin: 0;
        }

        &--disabled * {
            color: var(--s-color-disabled-primary);
        }
    }
}
